import * as Yup from 'yup';

export const webinarValidationSchema = Yup.object().shape({
    company: Yup.string().required('Company / organization is required'),
    contactPerson: Yup.string().required('Contact person is required'),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    webinarDate: Yup.date().required('Webinar date is required'),
    startTime: Yup.string().required('Start time is required'),
    endTime: Yup.string().required('End time is required'),
    webinarTitle: Yup.string().required('Webinar title is required'),
    onDemandStatus: Yup.string()
        .oneOf(['Client', 'Subscribed', 'Anonymous', 'Anonymous hidden'], 'Please select a valid on-demand status')
        .required('Please select the on-demand status'),

    presentationPowerPoint: Yup.mixed().nullable(),
    presentationPDF: Yup.mixed().nullable(),

    chapterDivision: Yup.array()
        .of(
            Yup.object().shape({
                slideNumber: Yup.string(),
                chapterTitle: Yup.string()
            })
        )
        .nullable(),

    callToAction: Yup.string(),
    pollQuestions: Yup.string(),
    survey: Yup.string(),
    receiveChatMessages: Yup.boolean(),
    followUpEmail: Yup.boolean(),
    knowledgeTest: Yup.string(),
    numberOfSpeakers: Yup.number(),
    numberOfChatModerators: Yup.number(),

    participants: Yup.array().of(
        Yup.object().shape({
            firstName: Yup.string().required('First name is required'),
            lastName: Yup.string().required('Last name is required'),
            phoneNumber: Yup.string().required('Phone number is required'),
            email: Yup.string().email('Invalid email address').required('Email is required'),
            role: Yup.string().oneOf(['Spreker', 'Chat-moderator', 'Overig'], 'Invalid role').required('Role is required'),
            present: Yup.string().oneOf(['Ja', 'Nee', 'Spreker op afstand (via inbellen)'], 'Invalid present status').required('Presence is required')
        })
    ),

    nameTitles: Yup.string(),
    studioBackgroundImage: Yup.string(),
    broadcastScreenImage: Yup.string(),

    showExternalVideo: Yup.boolean(),
    desktopSharing: Yup.boolean(),
    participantsVisibleToOthers: Yup.boolean(),

    lunchDinner: Yup.boolean(),
    numberOfPeopleDietaryRequirements: Yup.string(),
    subtitles: Yup.boolean(),
    hdVideoFile: Yup.boolean(),
    mp4File: Yup.boolean(),

    webinarGoal: Yup.string(),
    extraRemarks: Yup.string()
});
