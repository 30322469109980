import { RouterProvider } from 'react-router-dom';

import './App.css';
import { ToastContainer } from 'react-toastify';

import { router } from './routing';

function App() {
    return (
        <>
            <RouterProvider router={router} />
            <ToastContainer
                toastClassName={() => 'flex items-center p-4 rounded-md shadow-lg bg-blue-500 text-white max-w-xs'}
                bodyClassName={() => 'text-sm font-medium text-white'}
                position="bottom-right"
                autoClose={4000}
                hideProgressBar={true}
                newestOnTop={true}
                closeOnClick={true}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
                theme="light"
            />
        </>
    );
}

export default App;
