const Figure = ({ value, title, className }: { value: number, title?: string, className?: string }) => {
    return (
        <div className={`flex flex-col justify-around min-w-52 max-w-max h-64 bg-blue-600 items-center text-white ${className}`}>
            <div className="text-3xl">{title}</div>
            <div className="text-9xl">{value}</div>
        </div>
    );
};

export default Figure;
