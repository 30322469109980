import { Global, Sector } from './Widgets';

export const CustomerInsights = () => {
    return (
        <>
            <div className="flex flex-auto border-2 p-4 gap-5">
                <Global />
            </div>
            <div className="flex flex-auto border-2 p-4 gap-5">
                <Sector />
            </div>
        </>
    );
};

export default CustomerInsights;
