import { useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import { Button, DatePicker, Error, LabelElement, Loading, RadioGroup, SelectElement, SelectOption, TimePicker } from '@80db/core-ui';
import { Field, FieldArray, Formik, FormikProps } from 'formik';

import { useBookWebinars } from './hooks/useBookWebinars';
import { useForm } from './hooks/useForm';
import { webinarValidationSchema } from './validationSchema';
import { BookWebinarFormValues, OnDemandStatusOptions } from '../../types';
import { CollapsibleSection, FormContainer, FormikFileInputField, FormikTextInputField } from '../Shared';

export const BookWebinarForm = () => {
    const { createWebinarMutation } = useBookWebinars();
    const navigate = useNavigate();

    const onDemandStatusOptions: OnDemandStatusOptions[] = ['Client', 'Subscribed', 'Anonymous', 'Anonymous hidden'];

    const webinarGoals = [
        { value: 'Brand and/or community building', text: 'Brand and/or community building' },
        { value: 'Internal communication', text: 'Internal communication' },
        { value: 'Marketing and/or lead generation', text: 'Marketing and/or lead generation' },
        { value: 'Training and/or education', text: 'Training and/or education' }
    ];

    const initialValues: BookWebinarFormValues = {
        company: '',
        contactPerson: '',
        firstName: '',
        lastName: '',
        email: '',
        webinarDate: new Date(),
        startTime: '13:00',
        endTime: '14:00',
        webinarTitle: '',
        onDemandStatus: 'Anonymous',
        presentationPowerPoint: undefined,
        presentationPDF: undefined,
        chapterDivision: [],
        callToAction: '',
        pollQuestions: '',
        survey: '',
        receiveChatMessages: '',
        followUpEmail: true,
        knowledgeTest: '',
        numberOfSpeakers: 0,
        numberOfChatModerators: 0,
        participants: [],
        nameTitles: '',
        studioBackgroundImage: '',
        broadcastScreenImage: '',
        showExternalVideo: false,
        desktopSharing: false,
        participantsVisibleToOthers: true,
        lunchDinner: false,
        numberOfPeopleDietaryRequirements: 0,
        subtitles: false,
        hdVideoFile: false,
        mp4File: false,
        webinarGoal: '',
        extraRemarks: ''
    };

    const handleSubmit = async (values: BookWebinarFormValues, { setSubmitting }) => {
        try {
            await createWebinarMutation.mutateAsync(values);
            navigate('./dashboard');
        } catch (error) {
            // Errors are handled by the mutation's error state
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className="bg-white p-6 rounded shadow-md">
            {createWebinarMutation.isPending && (
                <div className="flex justify-center">
                    <Loading />
                </div>
            )}

            <h1 className="text-2xl font-bold mb-4">Book a Webinar</h1>
            <Formik initialValues={initialValues} validationSchema={webinarValidationSchema} enableReinitialize={true} onSubmit={handleSubmit}>
                {(formikProps: FormikProps<BookWebinarFormValues>) => {
                    const { values, isSubmitting, isValid, setFieldValue } = formikProps;
                    const { handleStartTimeChange, handleEndTimeChange, handleFormSubmit } = useForm(formikProps);

                    return (
                        <FormContainer>
                            <CollapsibleSection title="General Information">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <FormikTextInputField name="company" label="Company / Organization *" required placeholder="Enter company name" />
                                    <FormikTextInputField
                                        name="contactPerson"
                                        label="Contact Person *"
                                        required
                                        placeholder="Enter contact person's full name"
                                    />
                                    <FormikTextInputField name="firstName" label="First Name *" required placeholder="Enter first name" />
                                    <FormikTextInputField name="lastName" label="Last Name *" required placeholder="Enter last name" />
                                    <FormikTextInputField name="email" label="Email *" required placeholder="Enter email address" classNames="md:col-span-2" />

                                    <FormikTextInputField
                                        name="webinarTitle"
                                        label="Webinar Title *"
                                        classNames="md:col-span-2"
                                        required
                                        placeholder="Enter the title of the webinar"
                                    />

                                    <div className="form-control my-4">
                                        <label className="block text-sm font-semibold text-gray-700 mb-2">On-Demand Status</label>
                                        <Field name="onDemandStatus">
                                            {({ field, form }: { field: any, form: any }) => (
                                                <SelectElement
                                                    value={field.value}
                                                    onChange={(e) => form.setFieldValue(field.name, (e.target as HTMLSelectElement).value)}
                                                    errorMessage={form.errors.onDemandStatus && form.touched.onDemandStatus ? form.errors.onDemandStatus : ''}
                                                    classNames="form-select mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                                                >
                                                    {onDemandStatusOptions.map((status) => (
                                                        <SelectOption key={status} value={status} text={status} />
                                                    ))}
                                                </SelectElement>
                                            )}
                                        </Field>
                                    </div>
                                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                        <div className="relative">
                                            <DatePicker
                                                label="Webinar Date"
                                                value={values.webinarDate}
                                                onChange={(date: Date) => setFieldValue('webinarDate', date)}
                                                classNames="mt-1 block w-full p-2 border bg-white text-gray-700 rounded-md text-left"
                                            />
                                        </div>
                                        <div className="relative">
                                            <TimePicker
                                                value={values.startTime}
                                                onChange={handleStartTimeChange}
                                                classNames="mt-1 block w-full p-2 border bg-white text-gray-700 rounded-md"
                                                label="Start Time *"
                                            />
                                        </div>

                                        <div className="relative">
                                            <TimePicker
                                                value={values.endTime}
                                                onChange={handleEndTimeChange}
                                                classNames="mt-1 block w-full p-2 border bg-white text-gray-700 rounded-md"
                                                label="End Time *"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </CollapsibleSection>

                            <CollapsibleSection title="Presentation Files">
                                <FormikFileInputField name="presentationPowerPoint" label="Presentation (PowerPoint)" accept=".ppt,.pptx" />
                                <FormikFileInputField name="presentationPDF" label="Presentation (PDF)" accept=".pdf" />
                            </CollapsibleSection>

                            <CollapsibleSection title="Chapter Division">
                                <FieldArray name="chapterDivision">
                                    {({ push, remove }) => (
                                        <>
                                            {values.chapterDivision.map((_, index) => (
                                                <div key={index} className="flex space-x-2 mt-2">
                                                    <FormikTextInputField
                                                        name={`chapterDivision.${index}.slideNumber`}
                                                        label="Slide Number *"
                                                        required
                                                        placeholder="Enter slide number"
                                                    />
                                                    <FormikTextInputField
                                                        name={`chapterDivision.${index}.chapterTitle`}
                                                        label="Chapter Title *"
                                                        required
                                                        placeholder="Enter chapter title"
                                                    />
                                                    <button type="button" onClick={() => remove(index)} className="text-red-500">
                                                        Remove
                                                    </button>
                                                </div>
                                            ))}
                                            <button
                                                type="button"
                                                onClick={() => push({ slideNumber: '', chapterTitle: '' })}
                                                className="mt-2 px-4 py-2 bg-blue-600 text-white rounded-md"
                                            >
                                                Add Chapter
                                            </button>
                                        </>
                                    )}
                                </FieldArray>
                            </CollapsibleSection>

                            <CollapsibleSection title="Functionalities">
                                <div className="flex flex-col items-start space-y-2 mt-4">
                                    <label className="block text-sm font-medium text-gray-700">Receive Chat Messages</label>
                                    <Field
                                        name="receiveChatMessages"
                                        type="checkbox"
                                        checked={values.receiveChatMessages}
                                        className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                        onChange={() => setFieldValue('receiveChatMessages', !values.receiveChatMessages)}
                                    />
                                </div>

                                <div className="flex flex-col items-start space-y-2 mt-4">
                                    <label className="block text-sm font-medium text-gray-700">Follow Up Email</label>
                                    <Field
                                        name="followUpEmail"
                                        type="checkbox"
                                        checked={values.followUpEmail}
                                        className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                        onChange={() => setFieldValue('followUpEmail', !values.followUpEmail)}
                                    />
                                </div>

                                <div className="flex flex-col items-start space-y-2 mt-4">
                                    <label className="block text-sm font-medium text-gray-700">Knowledge Test</label>
                                    <Field
                                        as="textarea"
                                        name="knowledgeTest"
                                        className="textarea textarea-bordered w-full text-sm h-24 bg-white border-gray-300 bg-transparent"
                                        placeholder="Enter knowledge test details"
                                    />
                                </div>
                            </CollapsibleSection>

                            <CollapsibleSection title="Extras">
                                <FormikTextInputField
                                    name="nameTitles"
                                    label="Name Titles"
                                    classNames="w-full text-sm h-14 bg-transparent"
                                    placeholder="During the webinar, the names of the speakers are shown to the participants multiple times. The costs for name titles start at €295"
                                />
                                <FormikFileInputField
                                    name="studioBackgroundImage"
                                    label="Studio Background Image"
                                    placeholder="Upload image"
                                    accept="image/*"
                                    classNames="block w-full text-sm text-gray-700"
                                />
                                <FormikFileInputField
                                    name="broadcastScreenImage"
                                    label="Broadcast Screen Image"
                                    placeholder="Upload image"
                                    accept="image/*"
                                    classNames="block w-full text-sm text-gray-700"
                                />

                                <div className="form-control my-4">
                                    <RadioGroup label="Participants Visible to Others">
                                        <div className="flex items-center space-x-4">
                                            <div className="form-control">
                                                <LabelElement text="Yes" />
                                                <input
                                                    type="radio"
                                                    className="radio checked:bg-blue-500 h-4 w-4 text-blue-600"
                                                    name="participantsVisibleToOthers"
                                                    value="true"
                                                    checked={values.participantsVisibleToOthers === true}
                                                    onChange={() => setFieldValue('participantsVisibleToOthers', true)}
                                                />
                                            </div>
                                            <div className="form-control">
                                                <LabelElement text="No" />
                                                <input
                                                    type="radio"
                                                    className="radio checked:bg-blue-500 h-4 w-4 text-blue-600"
                                                    name="participantsVisibleToOthers"
                                                    value="false"
                                                    checked={values.participantsVisibleToOthers === false}
                                                    onChange={() => setFieldValue('participantsVisibleToOthers', false)}
                                                />
                                            </div>
                                        </div>
                                    </RadioGroup>
                                </div>

                                <div className="form-control my-4">
                                    <RadioGroup label="Subtitles for Webinar">
                                        <div className="flex items-center space-x-4">
                                            <div className="form-control">
                                                <LabelElement text="Yes" />
                                                <input
                                                    type="radio"
                                                    className="radio checked:bg-blue-500 h-4 w-4 text-blue-600"
                                                    name="subtitles"
                                                    value="true"
                                                    checked={values.subtitles === true}
                                                    onChange={() => setFieldValue('subtitles', true)}
                                                />
                                            </div>
                                            <div className="form-control">
                                                <LabelElement text="No" />
                                                <input
                                                    type="radio"
                                                    className="radio checked:bg-blue-500 h-4 w-4 text-blue-600"
                                                    name="subtitles"
                                                    value="false"
                                                    checked={values.subtitles === false}
                                                    onChange={() => setFieldValue('subtitles', false)}
                                                />
                                            </div>
                                        </div>
                                    </RadioGroup>
                                </div>

                                <div className="form-control my-4">
                                    <RadioGroup label="HD Video File">
                                        <div className="flex items-center space-x-4">
                                            <div className="form-control">
                                                <LabelElement text="Yes" />
                                                <input
                                                    type="radio"
                                                    className="radio checked:bg-blue-500 h-4 w-4 text-blue-600"
                                                    name="hdVideoFile"
                                                    value="true"
                                                    checked={values.hdVideoFile === true}
                                                    onChange={() => setFieldValue('hdVideoFile', true)}
                                                />
                                            </div>
                                            <div className="form-control">
                                                <LabelElement text="No" />
                                                <input
                                                    type="radio"
                                                    className="radio checked:bg-blue-500 h-4 w-4 text-blue-600"
                                                    name="hdVideoFile"
                                                    value="false"
                                                    checked={values.hdVideoFile === false}
                                                    onChange={() => setFieldValue('hdVideoFile', false)}
                                                />
                                            </div>
                                        </div>
                                    </RadioGroup>
                                </div>

                                <div className="form-control my-4">
                                    <RadioGroup label="MP4 File">
                                        <div className="flex items-center space-x-4">
                                            <div className="form-control">
                                                <LabelElement text="Yes" />
                                                <input
                                                    type="radio"
                                                    className="radio checked:bg-blue-500 h-4 w-4 text-blue-600"
                                                    name="mp4File"
                                                    value="true"
                                                    checked={values.mp4File === true}
                                                    onChange={() => setFieldValue('mp4File', true)}
                                                />
                                            </div>
                                            <div className="form-control">
                                                <LabelElement text="No" />
                                                <input
                                                    type="radio"
                                                    className="radio checked:bg-blue-500 h-4 w-4 text-blue-600"
                                                    name="mp4File"
                                                    value="false"
                                                    checked={values.mp4File === false}
                                                    onChange={() => setFieldValue('mp4File', false)}
                                                />
                                            </div>
                                        </div>
                                    </RadioGroup>
                                </div>

                                <div className="form-control my-4">
                                    <RadioGroup label="Lunch or Dinner Preference">
                                        <div className="flex items-center space-x-4">
                                            <div className="form-control">
                                                <LabelElement text="Yes" />
                                                <input
                                                    type="radio"
                                                    className="radio checked:bg-blue-500 h-4 w-4 text-blue-600"
                                                    name="lunchDinner"
                                                    value="true"
                                                    checked={values.lunchDinner === true}
                                                    onChange={() => setFieldValue('lunchDinner', true)}
                                                />
                                            </div>
                                            <div className="form-control">
                                                <LabelElement text="No" />
                                                <input
                                                    type="radio"
                                                    className="radio checked:bg-blue-500 h-4 w-4 text-blue-600"
                                                    name="lunchDinner"
                                                    value="false"
                                                    checked={values.lunchDinner === false}
                                                    onChange={() => setFieldValue('lunchDinner', false)}
                                                />
                                            </div>
                                        </div>
                                    </RadioGroup>
                                </div>

                                <div className="form-control my-4">
                                    <FormikTextInputField
                                        name="numberOfPeopleDietaryRequirements"
                                        label="Number of People / Dietary Requirements"
                                        classNames="label w-full text-sm font-bold h-22 bg-transparent"
                                        placeholder="Enter number of people / dietary requirements"
                                    />
                                </div>

                                <div className="form-control mt-4">
                                    <label className="block text-sm font-bold text-gray-700 mb-2">Webinar Goal</label>
                                    <Field name="webinarGoal">
                                        {({ field, form }: { field: any, form: any }) => (
                                            <SelectElement
                                                value={field.value}
                                                onChange={(e) => form.setFieldValue(field.name, (e.target as HTMLSelectElement).value)}
                                                errorMessage={form.errors.webinarGoal && form.touched.webinarGoal ? form.errors.webinarGoal : ''}
                                                classNames="form-select mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                                            >
                                                {webinarGoals.map((goal) => (
                                                    <SelectOption key={goal.value} value={goal.value} text={goal.text} />
                                                ))}
                                            </SelectElement>
                                        )}
                                    </Field>
                                </div>

                                <div className="mt-4">
                                    <FormikTextInputField
                                        name="extraRemarks"
                                        label="Extra Remarks"
                                        classNames="w-full text-sm h-24 bg-transparent"
                                        placeholder="Enter extra remarks or information about the webinar"
                                    />
                                </div>
                            </CollapsibleSection>

                            {createWebinarMutation.isError && (
                                <Error
                                    classNames="mb-4"
                                    code={createWebinarMutation.error.response?.status}
                                    message={createWebinarMutation.error.response?.data?.message || 'An error occurred while booking the webinar.'}
                                />
                            )}

                            <div className="mt-6 text-right">
                                <Button
                                    type="submit"
                                    variant="primary"
                                    classNames="mt-4 p-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                                    onClick={() => handleFormSubmit()}
                                    disabled={!isValid || isSubmitting}
                                >
                                    Submit
                                </Button>
                            </div>
                        </FormContainer>
                    );
                }}
            </Formik>
        </div>
    );
};
