import { useState } from 'react';

const CollapsibleSection = ({ title, children, isOpenByDefault }: { title: string, children: React.ReactNode, isOpenByDefault?: boolean }) => {
    const [isOpen, setIsOpen] = useState(isOpenByDefault || false);

    return (
        <div className="mb-4">
            <button
                type="button"
                className="w-full text-left font-semibold text-lg bg-gray-200 p-3 rounded-md focus:outline-none"
                onClick={() => setIsOpen(!isOpen)}
            >
                {title}
            </button>
            {isOpen && <div className="p-4 border border-gray-300 rounded">{children}</div>}
        </div>
    );
};

export default CollapsibleSection;
