const Card = ({ title, children, classNames }: { title: string, children: JSX.Element, classNames?: string }) => {
    return (
        <div className={`flex flex-col items-center gap-2 bg-white p-2 ${classNames}`}>
            <div className="text-lg">{title}</div>
            <div className="flex gap-2">{children}</div>
        </div>
    );
};

export default Card;
