import { useErrorBoundary } from 'use-error-boundary';

interface ErrorBoundaryWrapperProps {
    children: React.ReactNode
}

const ErrorBoundaryWrapper: React.FC<ErrorBoundaryWrapperProps> = ({ children }) => {
    const { ErrorBoundary, didCatch, error } = useErrorBoundary();

    if (didCatch) {
        return <div>Something went wrong: {error.message}</div>;
    }

    return <ErrorBoundary>{children}</ErrorBoundary>;
};

export default ErrorBoundaryWrapper;
