import React from 'react';

import { Layout } from '@80db/core-ui';

import { BookWebinarForm as Form } from './BookWebinarForm';

export const BookWebinarForm = () => {
    return (
        <Layout>
            <Form />
        </Layout>
    );
};
