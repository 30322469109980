import Registrations from './Registrations';
import WebinarsBroadcasted from './WebinarsBroadcasted';
import WebinarsBroadcastedLastYear from './WebinarsBroadcastedLastYear';

const Sector = () => (
    <>
        <WebinarsBroadcasted />
        <WebinarsBroadcastedLastYear />
        <Registrations />
    </>
);

export default Sector;
