export const apiPaths = {
    auth: {
        login: '/auth/login',
        changePassword: '/auth/change-password',
        resetPasswordInitialize: '/auth/reset-password/initialize',
        resetPassword: '/auth/reset-password',
        userInfo: '/auth/user-info',
        logout: '/auth/logout'
    },
    user: {
        getUser: (userId: string) => `/user/${userId}`,
        allUsers: '/user',
        unsafeUser: (userId: string) => `/user/unsafe/${userId}`
    },
    content: {
        getContent: (companyId: string, contentType: string, contentId: string, size: string) => `/content/${companyId}/${contentType}/${contentId}/${size}`,
        alternativeContent: (companyId: string, contentType: string, contentId: string, size: string) => `/${companyId}/${contentType}/${contentId}/${size}`
    },
    countries: {
        getAll: '/countries'
    },
    video: {
        list: (seminarId: string) => `/video/list/${seminarId}`,
        stream: (seminarId: string, videoname: string) => `/video/stream/${seminarId}/${videoname}`,
        youtubeDownload: '/video/youtubedownload',
        base: '/video',
        upload: (seminarId: string) => `/video/upload/${seminarId}`
    },
    moderatorReplySet: {
        linked: (id: string) => `/moderatorReplySet/linked/${id}`,
        available: (id: string) => `/moderatorReplySet/available/${id}`,
        base: (id: string) => `/moderatorReplySet/${id}`,
        update: (id: string) => `/moderatorReplySet/update/${id}`,
        addAndLink: (seminarId: string) => `/moderatorReplySet/addAndLink/${seminarId}`
    },
    confirmationTemplate: {
        update: '/confirmationTemplate/update',
        attach: (seminarId: string) => `/confirmationTemplate/attached/${seminarId}`,
        detach: (seminarId: string) => `/confirmationTemplate/detach/${seminarId}`,
        listByLanguage: (language: string) => `/confirmationTemplate/list/${language}`
    },
    notification: {
        smsReminder: (seminarId: string) => `/notification/sms-reminder/${seminarId}`,
        emailReminder: '/notification/email-reminder',
        emailReminderById: (reminderId: string) => `/notification/email-reminder/${reminderId}`,
        emailReminderBySeminar: (seminarId: string) => `/notification/email-reminder/${seminarId}`
    },
    presentation: {
        base: (id: string) => `/presentation/${id}`,
        linked: (id: string) => `/presentation/linked/${id}`,
        availablePresentations: (id: string) => `/presentation/${id}/availablePresentations`,
        linkPresentation: (id: string) => `/presentation/link/${id}`,
        slides: (pid: string, id: string) => `/slides/${pid}/${id}`
    },
    profile: {
        base: '/profile',
        describe: '/profile/describe',
        edit: (userId: string) => `/profile/edit/${userId}`,
        register: '/profile/register',
        delete: '/profile/delete',
        checkAttributes: '/profile/check-attributes'
    },
    survey: {
        linked: (seminarId: string) => `/survey/linked/${seminarId}`,
        available: (seminarId: string) => `/survey/available/${seminarId}`,
        updateBySeminar: '/survey/updateBySeminar',
        getById: (surveyId: string) => `/survey/${surveyId}`
    },
    exam: {
        linked: (seminarId: string) => `/exam/linked/${seminarId}`,
        available: (seminarId: string) => `/exam/available/${seminarId}`,
        updateBySeminar: '/exam/updateBySeminar',
        getById: (examId: string) => `/exam/${examId}`
    },
    companies: {
        base: '/companies',
        seminars: '/companies/seminars',
        collection: (companyId: string, collectionType: string) => `/companies/${companyId}/${collectionType}`,
        file: (companyId: string, collectionType: string, fileId: string) => `/companies/${companyId}/${collectionType}/${fileId}`
    },
    seminars: {
        base: '/seminars',
        details: (id: string) => `/seminars/${id}/details`,
        publish: (id: string) => `/seminars/${id}/publish`,
        unpublish: (id: string) => `/seminars/${id}/unpublish`,
        phase: (id: string, phase: string) => `/seminars/${id}/${phase}`,
        features: (id: string) => `/seminars/${id}/features`,
        sharing: (id: string) => `/seminars/${id}/sharing`,
        imagesInfo: (id: string) => `/seminars/${id}/images-info`,
        attachmentsInfo: (id: string) => `/seminars/${id}/attachments-info`,
        upcomingByAccess: (access: number) => `/seminars/${access}/upcoming-by-access`
    },
    system: {
        timeZones: '/system/time-zones'
    },
    subscription: {
        moderators: {
            linked: (seminarId: string) => `/subscription/moderators/linked/${seminarId}`,
            available: (seminarId: string) => `/subscription/moderators/available/${seminarId}`,
            update: (seminarId: string) => `/subscription/moderators/update/${seminarId}`
        },
        broadcasters: {
            linked: (seminarId: string) => `/subscription/broadcasters/linked/${seminarId}`,
            available: (seminarId: string) => `/subscription/broadcasters/available/${seminarId}`,
            update: (seminarId: string) => `/subscription/broadcasters/update/${seminarId}`
        },
        coresByUser: (userId: string) => `/subscription/cores-by-user/${userId}`,
        bySeminarId: (seminarId: string) => `/subscription/by-seminar-id/${seminarId}`
    }
};
