const GuestContent = () => (
    <div className="bg-gradient-to-b from-light-blue to-white flex-1 flex flex-col items-center px-4 pb-4 pt-6 sm:pt-24">
        <div className="sm:w-3/4 2xl:w-1/2 sticky top-6">
            <h2 className="font-medium font-podkova text-3xl break-words ">Hello there!</h2>Welcome to my.onlineseminar.com as your go-to online event supplier.
            We are very excited to show you what our platform can do for you. Now let’s start and get your first online event going. Camera, lights, action!!
            <img src="../../images/login-icon.svg" className="w-96 pt-4" alt="auth" />
        </div>
    </div>
);

export default GuestContent;
