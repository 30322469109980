import Card from '../Card';
import Figure from '../Figure';
const ActiveCompanies = () => {
    return (
        <Card title="Active Companies">
            <Figure value={96} />
        </Card>
    );
};

export default ActiveCompanies;
