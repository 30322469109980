import { FileInput } from '@80db/core-ui';
import { useField } from 'formik';

interface FormikFileInputFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string,
    label?: string,
    classNames?: string
}

export const FormikFileInputField = ({ name, label, ...props }: FormikFileInputFieldProps) => {
    const [, meta, helpers] = useField(name);
    const { error, touched } = meta;
    const { setValue } = helpers;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.currentTarget.files ? event.currentTarget.files[0] : null;
        setValue(file);
    };

    return <FileInput name={name} label={label} error={Boolean(error && touched)} errorMessage={error} onChange={handleChange} {...props} />;
};
