import { Bar } from 'react-chartjs-2';

import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import Card from '../Card';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const Registrations = () => {
    const data = {
        labels: ['Number of subscribers', 'Live viewers', 'On demand viewers'],
        datasets: [
            {
                data: [200, 60, 120],
                backgroundColor: ['blue', 'green', 'orange']
            }
        ]
    };

    const options = {
        indexAxis: 'y' as const,
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                anchor: 'end' as const,
                align: 'end' as const,
                color: 'black',
                formatter: (value) => {
                    return `${value}`;
                }
            }
        },
        scales: {
            x: {
                display: false,
                grid: {
                    display: false
                },
                max: Math.max(...data.datasets[0].data) + 50
            },
            y: {
                grid: {
                    display: false
                }
            }
        }
    };

    return (
        <Card title="Registrations and viewers">
            <Bar className="min-w-96" data={data} options={options} />
        </Card>
    );
};

export default Registrations;
