import { useEffect, useLayoutEffect, useState } from 'react';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { Loading } from '@80db/core-ui';
import { v4 as uuidv4 } from 'uuid';

import { Status, useExternalScript } from '../../hooks';
import { get } from '../../services';
import { apiPaths } from '../../services';

declare global {
    interface Window {
        OnlineSeminarModerator: any
    }
}

interface Subscription {
    id: string,
    userId: number,
    canModerate: boolean
}

export const ModerateSeminar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [subscriptionId, setSubscriptionId] = useState<string>(location?.state?.subscriptionId || '');
    const scriptState = useExternalScript(`${process.env.REACT_APP_MODERATE_URL}/scripts/embed.bundle.js`);

    const userId = sessionStorage.getItem('_userId');
    const seminarId = searchParams.get('Id');
    if (!seminarId) {
        navigate('/dashboard');
    }

    const fetchSubscription = async () => {
        const subscriptionDto: Subscription[] = await get(apiPaths.subscription.bySeminarId(seminarId as string));
        const subscription = subscriptionDto.find((s) => s.userId.toString() === userId?.toString() && s.canModerate);

        if (!subscription) {
            navigate('/dashboard');
        }
        const { id } = subscription || {};
        setSubscriptionId(id);

        return subscription;
    };

    useLayoutEffect(() => {
        if (window?.sockets?.length) {
            navigate(0);
            window.sockets.forEach((ws: WebSocket) => ws.close());
        }
    }, [window.sockets]);

    useEffect(() => {
        if (!subscriptionId) {
            fetchSubscription();
        }
    }, [subscriptionId]);

    useEffect(() => {
        if (subscriptionId && scriptState === Status.ready) {
            setIsLoading(false);
        }
    }, [subscriptionId, scriptState]);

    const moderatorParams = () => ({
        userId,
        seminarId,
        subscriptionId,
        userSessionId: uuidv4(),
        environment: process.env.REACT_APP_ENVIRONMENT
    });

    useEffect(() => {
        if (!isLoading) {
            window.OnlineSeminarModerator.embed('ols_moderator_container', moderatorParams());
        }
    }, [isLoading]);

    if (isLoading) {
        return (
            <div id="ols_moderator_container" className="h-full w-full">
                <div className="flex h-full">
                    <div className="m-auto">
                        <Loading />
                    </div>
                </div>
            </div>
        );
    }

    return <div id="ols_moderator_container" className="h-full w-full ml-2"></div>;
};
