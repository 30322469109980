import { toast } from 'react-toastify';

import { FormikProps } from 'formik';

import { BookWebinarFormValues } from '../../../types';

export const useForm = (formikProps: FormikProps<BookWebinarFormValues>) => {
    const { values, setFieldValue, errors, touched, isValid, submitForm } = formikProps;

    const addOneHour = (time: string): string => {
        const [hours, minutes] = time.split(':').map(Number);
        const newHours = (hours + 1) % 24;
        return `${String(newHours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    };

    const handleStartTimeChange = (startTime: string) => {
        setFieldValue('startTime', startTime);

        if (values.endTime && startTime >= values.endTime) {
            const newEndTime = addOneHour(startTime);
            setFieldValue('endTime', newEndTime);

            toast.warning(`End Time was adjusted to ${newEndTime} as it cannot be earlier than Start Time.`);
        }
    };

    const handleEndTimeChange = (endTime: string) => {
        if (endTime >= values.startTime) {
            setFieldValue('endTime', endTime);
        } else {
            const newEndTime = addOneHour(values.startTime);
            setFieldValue('endTime', newEndTime);

            toast.warning(`End Time was automatically adjusted to one hour later (${newEndTime}) than Start Time.`);
        }
    };

    const showInvalidFields = () => {
        const invalidFields = Object.keys(errors).filter((key) => touched[key as keyof BookWebinarFormValues] && errors[key as keyof BookWebinarFormValues]);

        if (invalidFields.length > 0) {
            invalidFields.forEach((field) => {
                toast.error(`${field}: ${errors[field as keyof BookWebinarFormValues]}`);
            });
        }
    };

    const handleFormSubmit = async () => {
        if (!isValid) {
            showInvalidFields();
        } else {
            await submitForm();
        }
    };

    return {
        handleFormSubmit,
        handleStartTimeChange,
        handleEndTimeChange
    };
};
