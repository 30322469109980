import { UseMutationResult, useMutation } from '@tanstack/react-query';

import { post } from '../../../services';
import { apiPaths } from '../../../services';
import { BookWebinarFormValues } from '../../../types';

interface CreateWebinarResponse {
    seminarId: string,
    message: string,
    status: string
}

interface WebinarError {
    response?: {
        status: number,
        data?: {
            message: string
        }
    }
}

export const useBookWebinars = () => {
    const createWebinarMutation: UseMutationResult<CreateWebinarResponse, WebinarError, BookWebinarFormValues, unknown> = useMutation({
        mutationFn: async (values: BookWebinarFormValues) => {
            const response = await post<CreateWebinarResponse>(apiPaths.seminars.base, values);
            return response;
        }
    });

    return { createWebinarMutation };
};
