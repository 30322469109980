import Card from '../Card';
import Figure from '../Figure';
const Seminars = () => {
    return (
        <Card title="Total Webinars on Platform">
            <Figure value={24} />
        </Card>
    );
};

export default Seminars;
