import { useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import { Logo, NavBar } from '@80db/core-ui';

import { Can } from '../../Auth/Can';
import { Profile } from '../Profile';

export const MainNav = () => {
    const navigate = useNavigate();
    const navBarMenuItems = [
        {
            name: 'icon',
            menuItemComponent: () =>
                useMemo(
                    () => (
                        <Logo
                            classNames="w-12 h-12 fill-blue-500"
                            onClick={() => {
                                navigate('/dashboard');
                            }}
                        />
                    ),
                    []
                )
        },
        {
            name: 'profile',
            menuItemComponent: () => useMemo(() => <Profile />, [])
        },
        {
            name: 'book-webinar',
            menuItemComponent: () => (
                <Can I="book" a="Seminar">
                    <button onClick={() => navigate('/book-webinar')} className="mt-4 p-2 bg-blue-600 text-white rounded-md hover:bg-blue-700">
                        Book
                    </button>
                </Can>
            )
        }
    ];
    return <NavBar navBarMenuItems={navBarMenuItems} classNames="rounded-full" />;
};
