import ActiveCompanies from './ActiveCompanies';
import ActiveUsers from './ActiveUsers';
import Seminars from './Seminars';
import ViewersWatching from './ViewersWatching';

const Global = () => (
    <>
        <ViewersWatching />
        <Seminars />
        <ActiveCompanies />
        <ActiveUsers />
    </>
);

export default Global;
