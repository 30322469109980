import { createBrowserRouter } from 'react-router-dom';

import { AuthLayout } from '../Auth';
import { HomeLayout, ProtectedLayout } from '../components';

export const router = createBrowserRouter([
    {
        path: '/',
        element: <AuthLayout />,
        children: [
            {
                element: <HomeLayout />,
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { UserLogin } = await import('./homepage');
                            return { Component: UserLogin };
                        }
                    },
                    {
                        path: 'login',
                        async lazy() {
                            const { UserLogin } = await import('./homepage');
                            return { Component: UserLogin };
                        }
                    },
                    {
                        path: 'forgotpassword',
                        async lazy() {
                            const { ForgotPassword } = await import('./homepage');
                            return { Component: ForgotPassword };
                        }
                    },
                    {
                        path: 'reset-password',
                        async lazy() {
                            const { ResetPassword } = await import('./homepage');
                            return { Component: ResetPassword };
                        }
                    }
                ]
            },
            {
                element: <ProtectedLayout />,
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { Dashboard } = await import('./dashboard');
                            return { Component: Dashboard };
                        }
                    },
                    {
                        path: 'dashboard',
                        async lazy() {
                            const { Dashboard } = await import('./dashboard');
                            return { Component: Dashboard };
                        }
                    },
                    {
                        path: 'book-webinar',
                        async lazy() {
                            const { BookWebinarForm } = await import('../components');
                            return { Component: BookWebinarForm };
                        }
                    },
                    {
                        path: 'account/:tabIndex',
                        async lazy() {
                            const { UserAccount } = await import('./user-account');
                            return { Component: UserAccount };
                        }
                    },
                    {
                        path: 'moderate',
                        async lazy() {
                            const { ModerateSeminar } = await import('./dashboard');
                            return { Component: ModerateSeminar };
                        }
                    },
                    {
                        path: 'broadcast',
                        async lazy() {
                            const { BroadcastSeminar } = await import('./dashboard');
                            return { Component: BroadcastSeminar };
                        }
                    },
                    {
                        path: 'insights',
                        async lazy() {
                            const { CustomerInsights } = await import('./insights');
                            return { Component: CustomerInsights };
                        }
                    }
                ]
            },
            {
                path: '*',
                element: <HomeLayout />
            }
        ]
    }
]);
