import { ReactNode, createContext, useMemo } from 'react';

import { flushSync } from 'react-dom';
import { useLocation, useNavigate } from 'react-router-dom';

import { AxiosResponse } from 'axios';

import { LoginFormValues } from '../../components';
import { useLocalStorage } from '../../hooks';
import { get, post } from '../../services';
import { apiPaths } from '../../services';
import { ChangePasswordValues, ResetPasswordValues } from '../types';

export interface AuthContextType {
    userAccessToken: string | null,
    login: (values: LoginFormValues) => Promise<unknown>,
    logout: () => void,
    changePassword: (values: ChangePasswordValues) => Promise<unknown>,
    forgetPassword: (email: string) => Promise<unknown>,
    resetPassword: (values: ResetPasswordValues) => Promise<unknown>
}

export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [userAccessToken, setUserAccessToken] = useLocalStorage('_token', null);
    const navigate = useNavigate();
    const location = useLocation();

    const login = async (values: LoginFormValues) => {
        try {
            const res: AxiosResponse = await post(apiPaths.auth.login, values);
            const { accessToken } = res.data;

            sessionStorage.setItem('_token', accessToken);
            flushSync(() => {
                setUserAccessToken(accessToken);
            });

            const userRes: AxiosResponse = await get(apiPaths.auth.userInfo);
            sessionStorage.setItem('_userId', userRes.data.userId);

            const { pathname, search, state } = location || {};
            setTimeout(() => {
                navigate(`${pathname ?? '/dashboard'}${search ?? ''}`, {
                    replace: true,
                    state
                });
            }, 100);
        } catch (err) {
            const errorMsg = `Login failed with error - ${Object.entries(err.response.data)}`;
            throw new Error(errorMsg);
        }
    };

    const logout = async () => {
        try {
            await post(apiPaths.auth.logout);
        } finally {
            setUserAccessToken(null);
            sessionStorage.clear();
            navigate('/login', { replace: true });
        }
    };

    const changePassword = async (values: ChangePasswordValues) => {
        return post(apiPaths.auth.changePassword, values)
            .then((res: AxiosResponse) => {
                const { accessToken } = res.data;
                sessionStorage.setItem('_token', accessToken);

                flushSync(() => {
                    setUserAccessToken(accessToken);
                });
            })
            .catch((err) => {
                const errorMsg = `Change password failed with error - ${Object.entries(err.response.data)}`;
                throw new Error(errorMsg);
            });
    };

    const forgetPassword = async (email: string) => await post(apiPaths.auth.resetPasswordInitialize, { email });

    const resetPassword = async (values: ResetPasswordValues) => await post(apiPaths.auth.resetPassword, values);

    const value = useMemo(
        () => ({
            userAccessToken,
            login,
            logout,
            changePassword,
            forgetPassword,
            resetPassword
        }),
        [userAccessToken]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
