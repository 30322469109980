import Card from '../Card';
import Figure from '../Figure';
const WebinarsBroadcasted = () => {
    return (
        <Card title="Webinars Broadcasted">
            <Figure value={23} />
        </Card>
    );
};

export default WebinarsBroadcasted;
