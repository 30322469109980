import Card from '../Card';
import Figure from '../Figure';
const WebinarsBroadcastedLastYear = () => {
    return (
        <Card title="Webinars Broadcasted Last Year">
            <Figure value={33} />
        </Card>
    );
};

export default WebinarsBroadcastedLastYear;
