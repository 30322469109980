import Card from '../Card';
import Figure from '../Figure';
const ActiveUsers = () => {
    return (
        <Card title="Active Users">
            <Figure value={1022} />
        </Card>
    );
};

export default ActiveUsers;
