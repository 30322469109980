import axios, { AxiosHeaders, InternalAxiosRequestConfig } from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_ADMINTOOL_GATEWAY_URL
});

axiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        const token = sessionStorage.getItem('_token');

        const isAuthRequest = ['/auth/login', '/auth/reset-password'].some((url) => config.url?.includes(url));

        if (!isAuthRequest && token) {
            config.headers = config.headers || new AxiosHeaders();
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        config.headers['Content-Type'] = 'application/json; charset=utf-8';
        return config;
    },
    (error: Error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401) {
            window.location.href = '/login';
            console.error('Unauthorized access - redirecting to login.');
        }
        // Always reject to allow global error handlers to catch it
        return Promise.reject(error);
    }
);

export default axiosInstance;
