import Card from '../Card';
import Figure from '../Figure';
const ViewersWatching = () => {
    return (
        <Card title="Viewers Watching Now">
            <>
                <Figure value={0} title="Live" className="bg-blue-600" />
                <Figure value={85} title="Ondemand" className="bg-orange-500" />
            </>
        </Card>
    );
};

export default ViewersWatching;
