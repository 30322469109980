import { AxiosResponse } from 'axios';

import axiosInstance from '../../axios-interceptor';

export const get = async <T>(url: string, config = {}): Promise<T> => {
    try {
        const response: AxiosResponse<T> = await axiosInstance.get(url, config);
        return response.data;
    } catch (error) {
        logApiError(error);
        throw error;
    }
};

export const post = async <T>(url: string, data?: any, config = {}): Promise<T> => {
    try {
        const response: AxiosResponse<T> = await axiosInstance.post(url, data, config);
        return response.data;
    } catch (error) {
        logApiError(error);
        throw error;
    }
};

export const put = async <T>(url: string, data: any, config = {}): Promise<T> => {
    try {
        const response: AxiosResponse<T> = await axiosInstance.put(url, data, config);
        return response.data;
    } catch (error) {
        logApiError(error);
        throw error;
    }
};

export const del = async <T>(url: string, config = {}): Promise<T> => {
    try {
        const response: AxiosResponse<T> = await axiosInstance.delete(url, config);
        return response.data;
    } catch (error) {
        logApiError(error);
        throw error;
    }
};

const logApiError = (error: any) => {
    console.error('API Error: ', error); // TODO - Implement error handling when serilog is implemented
};
